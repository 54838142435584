// src/components/FinsightCard.js
import React from 'react';
import { Card } from 'flowbite-react';
import Content from './content/Content';

const FinsightCard = ({title, date, link, insights}) => {
  console.log(title, date, link, insights)
  return (
    <div>
      <Content title={title} date={date} link={link} insights={insights} />
    </div>
  );
};

export default FinsightCard;
