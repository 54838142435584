import React from "react";

import { Badge } from 'flowbite-react';

const Content = ({title, date, link, insights}) => {
    const renderSentiment = (details) => {
        if (details.sentiment == "positive") {
            return (
                <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400 w-24 text-center">POSITIVE</span>
            )
        } else if (details.sentiment == "positive") {
            return (
                <span class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300 w-24 text-center">MIXED</span>
            )
        } else {
            return (
                <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">NEGATIVE</span>
            )
        }
    }

    return (
        <div className="w-full">
            {/* Header */}
            <div className="py-2.5">
                <p className="text-xs text-gray-400 my-1">{date}</p>
                <h1 className="text-3xl text-black rounded-base hover:text-indigo-700"><a href={link} target="_blank">{title}</a></h1>
            </div>

            {/* Labels (Dummy Labels as in the image) */}
            <div className="flex mb-6">
                <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300">Label 1</span>
                <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300">Label 2</span>
                <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300">Label 3</span>
            </div>

            {
                insights.map((details, index) => (
                    <div key={index} className="px-4 pb-4 pt-3 bg-insight my-3 border-solid border border-slate-50 rounded-lg shadow-md bg-white">
                        <h2 className="font-bold text-lg mb-2 uppercase text-[color:#32383c] underline underline-offset-8 decoration-4 decoration-[color:#019AE0]">{details.header}</h2>
                        
                        {Array.isArray(details.key_points) && details.key_points.length > 0 && (
                            <ul className="list-inside text-base mb-1 marker:text-indigo-500 divide-y-[color:#019AE0] divide-slate-300">
                                {
                                    details.key_points.map((point, index) => (
                                        <li key={index} className="py-3">
                                            <span className="text-xl square text-[color:#019AE0]">&#9632;</span>
                                            {
                                                typeof point === 'object' && point !== null && point.value && point.value.point
                                                    ? point.value.point
                                                    : point
                                            }
                                        </li>
                                    ))
                                }
                            </ul>
                        )}

                        <div className="mt-3">
                            <p className="mt-2 text-xxs text-slate-400 flex justify-center">FINSIGHT SENTIMENT</p>
                            <div className="flex justify-center mt-1">
                                {renderSentiment(details)}
                            </div>
                        </div>
                    </div>
                ))
            }

            {/* Source */}
            <div className="text-xs text-gray-500">
                Source: <a href="https://www.moneycontrol.com" target="_blank" className="text-blue-600">MoneyControl</a>
            </div>
        </div>
    )
}

export default Content;