// src/App.js
import React, { useEffect, useState } from 'react';
import Footer from './components/footer/Footer';
import Navbar from './components/navbar/Navbar';
import FinsightCardSlider from './components/slider/FinsightCardSlider';

function App() {
  const [data, setData] = useState([]); // Holds the data fetched from API
  const [page, setPage] = useState(1); // Current page number for pagination
  const [hasMore, setHasMore] = useState(true); // Check if there are more results
  const [resultsPerPage] = useState(3); // Number of results to fetch per request
  
  // Fetch data from the API
  const fetchData = async () => {
    if (!hasMore) return; // Prevent fetching if already loading or no more data
    try {
      const response = await fetch(`https://backend.growriddhi.com/insights?page=${page}`);
      const result = await response.json();
      
      // Append new results to the existing data
      if (result.data.length > 0) {
        setData(prevData => {
          const existingIds = new Set(prevData.map(item => item.article_id));
          const newItems = result.data.filter(item => {
              if (!existingIds.has(item.article_id)) {
                  existingIds.add(item.article_id); // Add new article_id to the Set
                  return true; // Keep the item
              }
              return false; // Discard duplicates
          });
          return [...prevData, ...newItems];
      });
      }

      // Determine if there are more results based on the length of the fetched items
      if (result.data.length < resultsPerPage) {
        setHasMore(false); // No more data to load
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Fetch initial data when the component mounts or when the page number changes
  useEffect(() => {
    fetchData();
  }, [page]); // Only run fetchData when page changes

  // Infinite scroll: Load more data when user scrolls to the bottom
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 50 >= // 50px buffer before reaching the bottom
      document.documentElement.offsetHeight
    ) {
      setPage(prevPage => prevPage + 1);
    }
  };

  // Add scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll); // Clean up listener on unmount
    };
  }, []); // Empty dependency array ensures this effect runs only on mount and unmount

  return (
    <div className="bg-gray-100">
      <Navbar />
      <div className="px-3 mb-2">
        {data.map((item, index) => (
          <FinsightCardSlider key={item.article_id} cardData={item} />
        ))}
        {/* {isLoading && <p>Loading more results...</p>} */}
        {!hasMore && <p>No more results</p>}
      </div>
      <Footer />
    </div>
  );
}

export default App;
