// src/components/FinsightCard.js
import React, { useRef, useState, useEffect } from 'react';
import FinsightCard from '../card/Card';

const FinsightCardSlider = ({ cardData }) => {
    const scrollRef = useRef(null);

    console.log(cardData)
    // State to control button visibility and active card index
    const [showLeftButton, setShowLeftButton] = useState(false);
    const [showRightButton, setShowRightButton] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const [totalCards, setTotalCards] = useState(3); // For this demo, assume 2 cards

    // Function to handle horizontal scrolling
    const scroll = (direction) => {
        const { scrollLeft, clientWidth } = scrollRef.current;

        const scrollAmount = direction === 'left' ? -clientWidth : clientWidth;
        scrollRef.current.scrollTo({
        left: scrollLeft + scrollAmount,
        behavior: 'smooth',
        });
    };

    // Function to toggle button visibility based on scroll position
    const checkScrollPosition = () => {
        const { scrollLeft, clientWidth, scrollWidth } = scrollRef.current;

        // Check if at the start (leftmost)
        setShowLeftButton(scrollLeft > 0);

        // Check if at the end (rightmost)
        setShowRightButton(scrollLeft < scrollWidth - clientWidth);

        // Update active index
        const newIndex = Math.round(scrollLeft / clientWidth);
        setActiveIndex(newIndex);
    };

    // Run the scroll position check when the component mounts and on scroll
    useEffect(() => {
        const scrollElement = scrollRef.current;
        checkScrollPosition(); // Initial check

        // Add scroll event listener
        scrollElement.addEventListener('scroll', checkScrollPosition);

        // Cleanup the event listener on unmount
        return () => {
        scrollElement.removeEventListener('scroll', checkScrollPosition);
        };
    }, []);

  return (
    <div className="relative max-w-7xl mx-auto my-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 bg-[#FFF8EE]">
      {/* Left Arrow for Slider */}
      {showLeftButton && (
        <button
          onClick={() => scroll('left')}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full z-10 ml-1 opacity-50"
        >
          &#9664;
        </button>
      )}

      {/* Scrollable Container */}
      <div
        className="flex overflow-x-auto scrollbar-hide scroll-smooth snap-x"
        ref={scrollRef}
      >
        {/* Multiple Cards (Clone the FinsightCard as needed) */}
        {
          cardData.insights.map((insights, index) => (
            <div key={index} className="w-full flex-shrink-0 mx-auto px-4 pt-1">
              <FinsightCard title={cardData.title} date={cardData.date} link={cardData.link} insights={insights} />
            </div>
          ))
        }
      </div>

      {/* Right Arrow for Slider */}
      {showRightButton && (
        <button
          onClick={() => scroll('right')}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full z-10 mr-1 opacity-50"
        >
          &#9654;
        </button>
      )}

      {/* Dotted Carousel Indicator */}
      <div className="flex justify-center mt-1 space-x-2 mb-3">
        {Array.from({ length: totalCards }).map((_, index) => (
          <div
            key={index}
            className={`w-2 h-2 rounded-full ${
              activeIndex === index ? 'bg-gray-800' : 'bg-gray-300'
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default FinsightCardSlider;
